import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';
import clsx from 'clsx';

import { DEFAULT_CONFIG_KEY } from '../../../../../../constants';
import { NavigationGroupProps } from '../../../../types';

import classes from './index.module.scss';

import { I18nLink } from '@/components/I18nLink';
import {
  isLocalSubitem,
  isNavigationBarSubitem,
} from '@/components/Navbar/helpers';

export const HamburgerNavigationGroup: React.FunctionComponent<
  NavigationGroupProps
> = ({ label, subitems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, lang } = useTranslation('navbar');

  return (
    <li
      className={classes.itemContainer}
      onClick={() => setIsOpen(!isOpen)}
      data-e2e-selector="hamburger-item"
    >
      <div
        className={clsx(classes.groupLabel, isOpen && classes.groupLabelOpen)}
      >
        {t(label)}
      </div>
      {isOpen && (
        <ul className={classes.group}>
          {subitems.map((subitem) => {
            if (!isNavigationBarSubitem(subitem)) return null;
            return (
              <li
                className={classes.groupItem}
                data-e2e-selector="hamburger-item"
                key={subitem.label}
              >
                {isLocalSubitem(subitem) ? (
                  <I18nLink href={`/${subitem.routeName}`}>
                    <a className={classes.groupItemAnchor}>
                      {t(subitem.label)}
                    </a>
                  </I18nLink>
                ) : (
                  <a
                    className={classes.groupItemAnchor}
                    href={
                      subitem.externalUrls[lang] ||
                      subitem.externalUrls[DEFAULT_CONFIG_KEY]
                    }
                    target={subitem.openInNewTab ? '_blank' : undefined}
                    rel={subitem.openInNewTab ? 'noopener' : undefined}
                  >
                    {t(subitem.label)}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};
