import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { I18nLink } from '../../../../../I18nLink';
import { NavigationItemProps } from '../../../../types';
import { HamburgerNavigationGroup } from '../HamburgerNavigationGroup';

import classes from './index.module.scss';

export const HamburgerNavigationItem: React.FunctionComponent<
  NavigationItemProps
> = ({ label, subitems }) => {
  const { t } = useTranslation('navbar');

  return subitems ? (
    <HamburgerNavigationGroup label={label.name} subitems={subitems} />
  ) : (
    <li className={classes.navigationItem} data-e2e-selector="hamburger-item">
      {label.isExternalUrl ? (
        <a className={classes.navigationItemAnchor} href={`${label.href}`}>
          {t(label.name)}
        </a>
      ) : (
        <I18nLink href={`${label.href}`}>
          <a className={classes.navigationItemAnchor}>{t(label.name)}</a>
        </I18nLink>
      )}
    </li>
  );
};
