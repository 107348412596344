import React, { useContext } from 'react';
import { animated, useTransition } from 'react-spring';

import { AccountButtons } from '../../../AccountButtons';
import { HamburgerNavigationItem } from '../HamburgerNavigationItem';

import classes from './index.module.scss';

import ConfigsContext from '@/contexts/configs';

interface Props {
  isOpen: boolean;
}

const HamburgerNavigationContent: React.FunctionComponent<Props> = ({
  isOpen,
}) => {
  const transitions = useTransition(isOpen, {
    from: {
      opacity: 0,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      clamp: true,
      friction: 20,
      mass: 1,
      tension: 950,
    },
  });

  const navItems = useContext(ConfigsContext)['transformedNavbar'];

  return transitions(
    (styles, item) =>
      item && (
        <animated.ul
          className={classes.hamburgerNavigationPopover}
          style={styles}
        >
          <div className={classes.accountButtonsWrapper}>
            <AccountButtons />
          </div>
          <ul className={classes.hamburgerNavigationItems}>
            {navItems.map(
              ({
                externalUrl,
                navItem,
                navItemHash,
                navItemHref,
                navItemSubitems,
              }) => (
                <HamburgerNavigationItem
                  label={{
                    name: navItem.label,
                    href: navItemHref,
                    hash: navItemHash,
                    isExternalUrl: !!externalUrl,
                  }}
                  subitems={navItemSubitems}
                  key={navItem.label}
                />
              ),
            )}
          </ul>
        </animated.ul>
      ),
  );
};

export default HamburgerNavigationContent;
